
.nav{
  background-color: #fff; 
}
.nav-item{
  color: black;
  
}
/* .nav-item :active{
  font-weight: bold;
  color: purple !important;
} */
.nav-item :hover{
  color: rgb(244, 143, 12) !important;
  font-weight: bold;
  /* font-size: large; */
}

.carosuel{
  background-image: url('./assets/background.jpg');
  background-size: cover;
  background-position: center;
  height: 70vh;
  width: 100%;
  position: relative;
}

bg-img{
  width:fit-content;
  height: 600px;

}

.para1 {
  text-align: start;
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left:12%;
  padding-right:18% ;

}
.para1 p{
  font-size: 20px;
}
.para2{
  background-color: rgb(231, 237, 240);

}

.para2 h1{
  color: rgb(67, 168, 168);
  text-align: center;
  text-decoration-line: underline;
  padding-bottom: 1em;
}
.row{
  display: flex;
  align-items: center;
  flex-direction: row;
}
.row img{
  padding-top: 5%;
  padding-right: 5%;
  height: 100%;
  width: 100%;
}
.column{
  display: flex; /* This sets up a flex container */
  flex-direction: column; /* Aligns the items in a column */
  
}


.column {
  flex: 1; /* This allows the column to take up remaining available space */
  padding: 0 20px;/* Optional: Adjust padding for spacing*/
}

.custombtn:hover{
  border-radius:20px;
  width:120px;
  align-content: center;
  border-radius: 20px;
  border: rgb(38, 16, 38) solid 1px ;
 
}

img{
  border-radius: 5em;
}

/* Css of form contact us */
.contactUs {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh; 
}

form {
  margin: 20px;
  width: 100%; 
}

.socialMedia{
  margin-top: 2em;

  text-align: center;
 
}



/* modal */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(27, 27, 27, 0.541);
  display: flex;
  align-items: center;
}
.overlay img {
  display: block;
  max-width: 70%;
  max-height: 90%;
  margin: 60px auto;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
}
.overlay > span {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  color: #ffffff;
  z-index: 999;
  cursor: pointer;
}
.overlay-arrows_left {
  display: flex;
  background-color: #6d6d6d;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  width: 50px;
  height: 50px;
  left: 0;
  z-index: 999;
}

.overlay-arrows_left svg {
  width: 50px;
  height: 50px;
}

.overlay-arrows_right svg {
  width: 50px;
  height: 50px;
}

.overlay-arrows_right {
  display: flex;
  background-color: #6d6d6d;
  justify-content: space-between;
  position: absolute;
  right: 0;
  width: 50px;
  height: 50px;
  top: 50%;
  z-index: 999;
}

.btn-orange {
  background-color: orange;
  border-color: orange;
  color: white;
}

.btn-orange:hover {
  background-color: darkorange;
  border-color: darkorange;
}
