
.testimonial {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}
.testimonial .quote {
  background-color: #f1c40f;
  color: #fff;
  padding: 20px;
  border-radius: 8px 8px 0 0;
  position: relative;
}
.testimonial .quote:before {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 24px;
  position: absolute;
  top: 10px;
  left: 10px;
}
.testimonial .quote p {
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
}
.testimonial .author {
  display: flex;
  align-items: center;
  padding: 10px;
}
.testimonial .author img {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin-right: 10px;
}
.testimonial .author .info {
  flex: 1;
}
.testimonial .author .info h3 {
  margin: 0;
  color: orange;
  font-size: 18px;
}
.testimonial .author .info p {
  margin: 5px 0;
  color: #999999;
  font-size: 14px;
}
.testimonial .author .info .stars {
  color: #f1c40f;
}



/* Skeleton Base Styles */
.skeleton {
  background-color: #f3f3f3;
  border-radius: 8px;
  padding: 20px;
  overflow: hidden;
  position: relative;
}

.skeleton-box {
  background: #e0e0e0;
  border-radius: 8px;
  margin-bottom: 15px;
  padding: 10px;
  animation: shimmer 1.5s infinite linear;
}

.skeleton-bar {
  background: #e0e0e0;
  height: 16px;
  border-radius: 4px;
  margin: 8px 0;
  animation: shimmer 1.5s infinite linear;
}

.skeleton-avatar {
  width: 75px;
  height: 75px;
  background: #e0e0e0;
  border-radius: 50%;
  animation: shimmer 1.5s infinite linear;
}

.skeleton-icon {
  color: #e0e0e0;
  font-size: 18px;
  margin-right: 4px;
  animation: shimmer 1.5s infinite linear;
}

/* Animation for Shimmer Effect */
@keyframes shimmer {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
}

.skeleton-bar,
.skeleton-avatar,
.skeleton-icon {
  background: linear-gradient(
    to right,
    #e0e0e0 0%,
    #f0f0f0 50%,
    #e0e0e0 100%
  );
  background-size: 400% 100%;
}
